import { Box, Typography } from "@mui/material";
import { HtmlTag } from "components/elements/display/HtmlTag";

import { usePage } from "hooks/usePage";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";
import { Config } from "tools/utils/config";
import { LinkToAboutUs } from "./LinkToAboutUs";
import { SkeletonAboutUs } from "components/elements/skeleton/SkeletonAboutUs";

const AboutUsHome: React.FC = () => {
  const id = Config.ID_ABOUT_US_ON_HOME_PAGE_BLOCK;
  const { object, loading } = usePage(id, true, "about_us_block_home");

  if (!id) return null;
  if (loading)
    return (
      <Box sx={{ mt: 3 }}>
        <SkeletonAboutUs />
      </Box>
    );
  if (!object) return null;
  return (
    <Box className="mainBanner">
      <Box
        sx={{
          width: "100%",
          height: "100%",
          backgroundImage: `url(${CommonTools.processObjectField(object, [
            "_galleryDefault",
            "cdnurl",
          ])})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          "::after": {
            content: '""',
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,

            borderTop: "none",
            boxSizing: "border-box",
            pointerEvents: "none",
          },
        }}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "start",
              mr: { lg: 60 },
            }}>
            <Typography
              data-aos="fade-right"
              data-aos-delay="0"
              sx={{
                fontSize: { xs: 36, md: 48 },
                fontWeight: "bold",

                color: "#000",
                mt: 3,
                px: { xs: 6, sm: 4, md: 0 },
                textAlign: { xs: "center", lg: "start" },
              }}>
              {CommonTools.processObjectField(object, ["_name"])}
            </Typography>
            <Box
              data-aos="fade-right"
              data-aos-delay="100"
              sx={{
                maxWidth: "460px",
                mt: 4,

                fontSize: { xs: "20px", md: "24px" },
                px: { xs: 6, sm: 4, md: 0 },
                color: "#000",
                textAlign: { xs: "center", lg: "start" },
              }}>
              <HtmlTag
                content={CommonTools.processObjectField(object, [
                  "_description",
                ])}
              />
            </Box>
            <Box
              data-aos="fade-right"
              data-aos-delay="150"
              sx={{
                mt: 3,
                width: "100%",
                display: "flex",
                justifyContent: { xs: "center", lg: "start" },
              }}>
              <Box sx={{ px: { xs: 6, sm: 4, md: 0 } }}>
                <LinkToAboutUs />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export { AboutUsHome };
