import React from "react";

import { AdvertisementDto } from "dto/static/advertisement.dto";
import { Box, Typography } from "@mui/material";
import { useAdvertisementList } from "hooks/useAdvertisementList";
import { AdvertisementSection } from "tools/types/advertisementsection";

import { AdvertisementItem } from "./AdvertisementItem";
import { useLabel } from "hooks/useLabel";
import { logger } from "tools/utils/logger";
import { MyIcon } from "components/elements/icon/MyIcon";
import { IconType } from "components/elements/icon/IconContext";
import whyUsImage from "assets/images/whyUs.png";
import { SkeletonAdvertisementBenefitsList } from "components/elements/skeleton/SkeletonAdvertisementBenefitsList";

const AdvertisementBenefitsList: React.FC = () => {
  const { LL } = useLabel();

  const { objects, loading } = useAdvertisementList(
    AdvertisementSection.BENEFITS,
    true,
    "benefits_advertisement_list"
  );

  const processItem = (item: AdvertisementDto, index: number) => {
    if (!item) return null;
    return (
      <Box
        data-aos="fade-up"
        data-aos-delay={`${index * 300}`}
        sx={{ position: "relative" }}
        key={index}>
        <Box
          sx={{
            background: "rgba(201,167,77, 0.12)",
            borderRadius: "50%",
            width: 200,
            height: 200,
            position: "absolute",
            top: -80,
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: -1,
          }}
        />
        <AdvertisementItem key={index} index={index} item={item} />
      </Box>
    );
  };

  if (loading) return <SkeletonAdvertisementBenefitsList lines={4} />;
  if (!objects) return null;
  if (!objects.length) return null;

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        backgroundImage: `url(${whyUsImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
        zIndex: 1,

        "::after": {
          content: '""',
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,

          borderTop: "none",
          boxSizing: "border-box",
          pointerEvents: "none",
        },
      }}>
      <Box
        sx={{
          height: "90vh",
          width: "100%",
          maxHeight: { xs: "1300px", sm: "850px", md: "800px" },
          minHeight: { xs: "1300px", sm: "850px", md: "800px" },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          mt: { xs: 20, sm: 16, md: 2 },
          py: 10,
        }}>
        <Typography
          sx={{
            fontSize: { xs: "36px", md: "48px" },
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
            mt: 4,
            mb: { xs: 20, sm: 15 },
          }}>
          {LL("why_us")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyItems: "center",
            maxWidth: "1920px",
            flexDirection: ["column", "row", "row"],
            flexWrap: "wrap",
            justifyContent: "space-around",
            alignItems: "start",
            px: { xs: "auto", md: 10 },
            "> *": {
              width: ["100%", "50%", "25%"],
              minHeight: "350px",
            },
          }}>
          {objects.map((item: AdvertisementDto, index: number) =>
            processItem(item, index)
          )}
        </Box>
      </Box>
    </Box>
  );
};

export { AdvertisementBenefitsList };
