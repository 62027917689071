import React from "react";
import { IPage } from "interfaces/page.interface";
import { Box, Container } from "@mui/material";
import { useUser } from "hooks/useUser";
import { LoginForm } from "components/authentication/LoginForm";

import { Config } from "tools/utils/config";
import { AdvertisementLoginPage } from "components/static/advertisement/AdvertisementLoginPage";
import { useSeoInfo } from "hooks/useSeoInfo";

const LoginPage: React.FC<IPage> = () => {
  const { login } = useUser();
  useSeoInfo("login");
  return (
    <Box
      sx={{
        maxWidth: "1920px",
        marginX: "auto",
        width: "100%",
        mt: `${Config.HEADER_HEIGHT}px`,
        minHeight: {
          xs: `calc(100vh - ${Config.HEADER_HEIGHT}px - ${Config.FOOTER_HEIGHT_MOBILE}px  )`,
          md: `calc(100vh - ${Config.HEADER_HEIGHT}px - ${Config.FOOTER_HEIGHT}px )`,
        },
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
      <Container
        maxWidth="sm"
        sx={{
          height: "100%",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          // mr: { xs: "auto", md: 0 },
          mr: 0,
          ml: 0,
          px: 0,
          // mt: { xs: 8, md: 16 },
          mb: 2,
        }}>
        <Box
          sx={{
            // mt: "64px",
            display: "flex",
            alignItems: "center",
            alignContent: "center",
            width: "100%",
            flexDirection: "column",
            justifyContent: "center",
            px: { xs: 2, sm: 10 },
            pb: 6,
            pt: 6,
            borderRadius: 4,
          }}>
          <LoginForm onSubmit={login} />
        </Box>
      </Container>

      <AdvertisementLoginPage />
    </Box>
  );
};

export { LoginPage };
