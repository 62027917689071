import { FilterDto } from "dto/product/filter.dto";
import React from "react";
import { FilterGeneralItem } from "./FilterGeneralItem";
import { FilterDictionarList } from "../filterdictinar/FilterDictinarList";
import { CommonTools } from "tools/utils/common.tool";
import { RequestCriteriaDTO } from "dto/app/requestcriteria.dto";

type Props = {
  item: FilterDto;
  addCriteriaFilter: (id: string, value: string) => void;
  removeCriteriaFilter: (id: string, value: string) => void;
  criteriaFilter: RequestCriteriaDTO[];
};

const FilterDictionar: React.FC<Props> = ({
  item,
  addCriteriaFilter,
  removeCriteriaFilter,
  criteriaFilter,
}) => {
  if (!item) return null;

  return (
    <FilterGeneralItem item={item}>
      <FilterDictionarList
        idFilter={CommonTools.processObjectField(item, ["id"])}
        addCriteriaFilter={addCriteriaFilter}
        removeCriteriaFilter={removeCriteriaFilter}
        criteriaFilter={criteriaFilter}
      />
    </FilterGeneralItem>
  );
};

export { FilterDictionar };
