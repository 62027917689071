import React from "react";

import { TestimonialDTO } from "dto/static/testimonial.dto";
import { Box, Typography } from "@mui/material";
import { useList } from "hooks/useList";
import { TestimonialService } from "services/static/testimonial.service";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import { Status } from "tools/types/status";
import { TestimonialItem } from "./TestimonialItem";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { Carousel } from "components/elements/carousel/MyCarousel";
import { useLabel } from "hooks/useLabel";
import { SkeletonTestimonialList } from "components/elements/skeleton/SkeletonTestimonialList";

const service = new TestimonialService();
const TestimonialList: React.FC = () => {
  const { LL } = useLabel();
  const getList = (cb?: any, cbParams?: any, req?: RequestListDTO) => {
    service.getList(cb, cbParams, req);
  };

  const [loading, objects] = useList<TestimonialDTO>(
    getList,
    new RequestListDTO(
      [RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()])],
      1,
      -1,
      [RequestSortCriteriaDTO.prepareSortCriteria("order", true)]
    ),
    [],
    true,
    "testimonial_page_site"
  );

  const processItem = (item: TestimonialDTO, index: number) => {
    if (!item) return null;
    return (
      <Box key={index} sx={{ py: 3 }}>
        <TestimonialItem item={item} />
      </Box>
    );
  };
  if (loading)
    return (
      <Box mt={3}>
        <SkeletonTestimonialList />
      </Box>
    );
  if (!objects) return null;
  if (!objects.length) return null;
  return (
    <Box
      sx={{
        background: "rgba(201,167,77, 0.12)",
        px: 6,
        py: 10,
        // display: "flex",
        // justifyContent: "center",
        // flexDirection: "column",
        // alignItems: "center",
      }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}>
        <Box sx={{ width: "100%", maxWidth: "900px", textAlign: "center" }}>
          <Typography
            sx={{
              fontSize: { xs: "18px", md: "24px" },
              color: "#C9A74D",
              textTransform: "uppercase",
              fontWeight: "500",
            }}>
            {LL("what_our_clients_say")}
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "36px", md: "48px" },
              textTransform: "uppercase",
              fontWeight: "bold",
            }}>
            {LL("testimonials")}
          </Typography>
        </Box>
        <Box
          sx={{ width: "100%", maxWidth: "900px", textAlign: "center", mt: 3 }}>
          {objects.length === 1 ? (
            objects.map((item: TestimonialDTO, index: number) =>
              processItem(item, index)
            )
          ) : (
            <Carousel _slidesToShow={1} carouselStyle="testimonial">
              {objects.map((item: TestimonialDTO, index: number) =>
                processItem(item, index)
              )}
            </Carousel>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export { TestimonialList };
