import { List } from "@mui/material";
import { UserDto, UserMenuType } from "dto/user/user.dto";
import { useDrawer } from "hooks/useDrawer";
import { useUser } from "hooks/useUser";
import React from "react";
import { RouteTools } from "tools/utils/route.tool";
import { UserDrawerMenuItem } from "./UserDrawerMenuItem";
import { useResource } from "hooks/useResource";


const UserDrawerMenu: React.FC = () => {
  const { logout, isAuth } = useUser();
  const { closeAllDrawers } = useDrawer();
  const { handleChangeTabUserProfile } = useResource();
  const handleNavigate = (url?: string, anchor?: string) => {
    if (!url) return;
    if (url === "/profile") handleChangeTabUserProfile(undefined, anchor ?? "");
    else RouteTools.setHistory(url, {}, anchor);
    closeAllDrawers();
  };
  const handleLogout = (url?: string) => {
    if (!url) return;
    logout();
    RouteTools.setHistory(url, {});
    closeAllDrawers();
  };
  const objects: UserMenuType[] = UserDto.processUserMenu(
    [
      ...UserDto.getActionBasicAction(handleNavigate),
      UserDto.addAction("logout", handleLogout),
    ],
    isAuth
  );

  return (
    <List sx={{}}>
      {objects.map((item, index) => (
        <UserDrawerMenuItem key={index} item={item} />
      ))}
    </List>
  );
};

export { UserDrawerMenu };
