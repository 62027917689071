import { useDrawer } from "hooks/useDrawer";
import React from "react";
import { Box, Drawer, List, Stack, Typography } from "@mui/material";
import { Config } from "tools/utils/config";
import {
  CategoryProductDto,
  GroupedCategory,
} from "dto/product/categoryproduct.dto";

import { MyButton } from "components/elements/button/MyButton";
import { useLabel } from "hooks/useLabel";
import { DrawerCloseButton } from "./DrawerCloseButton";
import { DrawerGroupSecondItem } from "./DrawerGroupSecondItem";
import { MyIcon } from "components/elements/icon/MyIcon";
import { IconType } from "components/elements/icon/IconContext";
import { SkeletonMenuRow } from "components/elements/skeleton/SkeletonMenu";

// Stilul in main.css background-color e setat cu !important .MuiDrawer-paper denumirea class
const SecondDrawer: React.FC = () => {
  const {
    openSecondaryDrawer,
    closeAllDrawers,
    secondaryCategoryObjects,
    selectedMainCategory,
    closeSecondaryDrawer,
  } = useDrawer();
  const { LL } = useLabel();
  const processItem = (item: GroupedCategory, index: number) => {
    return <DrawerGroupSecondItem key={index} item={item} />;
  };
  const processList = () => {
    if (!secondaryCategoryObjects)
      return (
        <Box sx={{ px: 6 }}>
          <SkeletonMenuRow lines={10} />
        </Box>
      );
    if (!secondaryCategoryObjects.length) return null;
    const objects = CategoryProductDto.groupByParent(secondaryCategoryObjects);
    if (!objects) return null;
    return objects.map((item, index) => processItem(item, index));
  };

  if (!selectedMainCategory) return null;
  return (
    <Drawer
      open={openSecondaryDrawer}
      onClose={closeAllDrawers}
      anchor={Config.DRAWER_ANCHOR}>
      <Box
        sx={{
          width: { xs: "auto", sm: Config.DRAWER_WIDTH_MAIN },
        }}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}>
          <Box>
            <MyButton
              onClick={closeSecondaryDrawer}
              variant="text"
              disableRipple
              width={"auto"}
              className="myButtonAnimation"
              sx={{
                color: "black",
                mt: "45px",
                ml: 3,
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "start",
              }}
              startIcon={
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    opacity: 0.9,
                  }}>
                  <MyIcon type={IconType.LEFT_ARROW} />
                </Box>
              }>
              <Typography
                className="textAnimation"
                sx={{ textTransform: "capitalize" }}>
                {LL("bt_back")}
              </Typography>
            </MyButton>
          </Box>

          <DrawerCloseButton />
        </Stack>
        <List>{processList()}</List>
      </Box>
    </Drawer>
  );
};

export { SecondDrawer };
