import * as React from "react";
import { Box, Divider } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Unstable_Grid2";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { inherits } from "util";
import { useResponsive } from "hooks/useResponsive";

interface SkeletonProps {
  lines?: number;
  width?: number;
}

const SkeletonProductListHome: React.FC<SkeletonProps> = ({ lines }) => {
  const {
    matchesXS,
    matchesSM,
    matchesMD,
    matchesLG,
    downXS,
    downLG,
    downXL,
    downSM,
  } = useResponsive();

  let gridItemsCount;
  if (downSM) {
    gridItemsCount = 12;
  } else if (downLG) {
    gridItemsCount = 6;
  } else if (downXL) {
    gridItemsCount = 4;
  } else {
    gridItemsCount = 3;
  }

  const gridItem = (
    <Grid xs={gridItemsCount}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignContent: "center",
          alignItems: "center",
          // marginX: "auto",
          flexDirection: "column",
          width: "100%",
        }}>
        <Box sx={{ width: "94%", height: "595px" }}>
          <Skeleton variant="rectangular" sx={{ height: "435px" }} />
          <Box sx={{ px: 1 }}>
            <Skeleton
              variant="text"
              width="300px"
              sx={{ height: "30px", mt: 1 }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              px: 1,
              mt: 3,
            }}>
            <Skeleton variant="text" width="130px" sx={{ height: "25px" }} />
            <Skeleton variant="text" width="130px" sx={{ height: "25px" }} />
          </Box>
        </Box>
      </Box>
    </Grid>
  );

  return (
    <Box sx={{ width: "100%", height: "auto" }} className="">
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          maxHeight: { xs: "630px", md: "770px" },

          //   margin: "auto",
          //   padding: "20px 0px 20px 0px",
        }}>
        <Skeleton
          variant="text"
          sx={{
            width: { xs: "300px", md: "400px" },
            fontSize: { xs: "24px", md: "34px" },
            my: 8,
          }}
        />
        <Grid
          container
          sx={{
            // maxWidth: "1522px",
            width: "100%",
            overflow: "hidden",
          }}
          spacing={2}>
          {Array(lines)
            .fill(null)
            .map((_, index) => (
              <React.Fragment key={index}>{gridItem}</React.Fragment>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

export { SkeletonProductListHome };
