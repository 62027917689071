import { Box } from "@mui/material";
import { MenuBlock } from "components/footer/MenuBlock";

import { SocialBlock } from "components/footer/SocialBlock";
import { CopyRightPage } from "components/static/page/block/CopyRightPage";
import { IPage } from "interfaces/page.interface";
import React from "react";

import { Logo } from "./Logo";

const Footer: React.FC<IPage> = () => {
  return (
    <Box>
      <Box
        sx={{
          backgroundColor: "black",
          color: "white",
          py: 4,
          bottom: "0",
          display: "flex",
          justifyContent: "center",
          px: 4,
        }}>
        <Box
          sx={{
            px: "20px",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            // alignItems: { sx: "start", md: "center" },
            justifyContent: "space-between",
            alignItems: "center",
            // maxWidth: "1000px",
            width: "100%",
            // background: "red",
            height: "100%",
            gap: 4,
          }}>
          <Box>
            <Logo style={{ fill: "#fff" }} />
          </Box>
          <Box>
            <MenuBlock />
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <CopyRightPage />
            </Box>
          </Box>
          <Box>
            <SocialBlock />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export { Footer };
