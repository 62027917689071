import React from "react";

import { AdvertisementDto } from "dto/static/advertisement.dto";
import { Box, Container, Skeleton, Typography } from "@mui/material";
import { useAdvertisementList } from "hooks/useAdvertisementList";
import { AdvertisementSection } from "tools/types/advertisementsection";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { AdvertisementItem } from "./AdvertisementItem";
import { useLabel } from "hooks/useLabel";
import { NavButton } from "components/elements/button/NavButton";
import { SkeletonLoginPage } from "components/elements/skeleton/SkeletonLoginPage";

const AdvertisementLoginPage: React.FC = () => {
  const { objects, loading } = useAdvertisementList(
    AdvertisementSection.LOGIN_PAGE_BENEFITS,
    true,
    "login_page_advertisement_list"
  );
  const { LL } = useLabel();
  const processItem = (item: AdvertisementDto, index: number) => {
    if (!item) return null;
    return <AdvertisementItem key={index} index={index} item={item} />;
  };

  if (loading) return <SkeletonLoginPage />;
  if (!objects) return null;
  if (!objects.length) return null;

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: { xs: "none", md: "flex" },
        minHeight: "455px",
        height: "auto",

        alignContent: "center",
        alignItems: "start",
        // height: "100%",
        // width: "200px",
        backgroundColor: "black",
        mt: 2,
        mb: 2,
        ml: 0,
        mr: 0,
      }}>
      <Box
        // className="shadowBox"
        sx={{
          // mt: "64px",
          display: "flex",
          alignItems: "center",
          alignContent: "center",
          height: "auto",
          width: "100%",
          flexDirection: "column",
          justifyContent: "center",
          px: { xs: 4, sm: 6 },
          pb: 6,
          pt: 6,
          borderRadius: 4,
          mt: 3,
        }}>
        <Box sx={{ width: "100%", minHeight: "240px", height: "auto", mb: 6 }}>
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "bold",
              textTransform: "initial",
              color: "white",
            }}>
            {LL("create_account_title")}
          </Typography>
          <Typography
            sx={{
              mt: 4,
              fontSize: "16px",
              color: "white",
              fontWeight: "600",
            }}>
            {LL("create_account_benefits")}
          </Typography>
          {objects.map((item: AdvertisementDto, index: number) =>
            processItem(item, index)
          )}
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "end",
          }}>
          <Box
            className="listItemButton"
            sx={{
              width: "100%",
              height: "45px",
              background: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              boxShadow: "0 0 0 0 white, 0 0 0 0 white",

              "&:hover": {
                boxShadow: "0 3px 0 0 white, 0 -3px 0 0 white",
                transition: "all 0.2s",
              },
            }}>
            <NavButton
              href={"/signup"}
              buttonTheme="blackButton"
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
                ml: 2,
              }}>
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "15px",
                  mt: "3px",
                  fontWeight: "bold",
                }}>
                {LL("registerNow")}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: "4px",
                }}>
                <KeyboardArrowRightIcon
                  className="iconSvg2"
                  // sx={{ display: "none" }}
                />
              </Box>
            </NavButton>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export { AdvertisementLoginPage };
