import React from "react";
import { IPage } from "interfaces/page.interface";
import { Box } from "@mui/material";
import { AdvertisementHomeTopBlock } from "components/static/advertisement/AdvertisementHomeTopBlock";
import { ProductListHome } from "components/product/product/home/ProductListHome";
import { AdvertisementBenefitsList } from "components/static/advertisement/AdvertisementBenefitsList";

import { AboutUsHome } from "components/static/page/block/AboutUsHome";
import { TestimonialList } from "components/static/testimonial/TestimonialList";
import { PartnerList } from "components/static/partner/PartnerList";
import { GalleryHome } from "components/static/page/block/GalleryHome";
import { ContactHome } from "components/static/page/block/ContactHome";
import { useSeoInfo } from "hooks/useSeoInfo";

const HomePage: React.FC<IPage> = () => {
  useSeoInfo();
  return (
    <Box>
      <AdvertisementHomeTopBlock />
      <AboutUsHome />
      <ProductListHome />
      <AdvertisementBenefitsList />
      <GalleryHome />
      <TestimonialList />
      <PartnerList />
      <ContactHome />
    </Box>
  );
};

export { HomePage };
