import * as React from "react";
import { Box, Divider } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Unstable_Grid2";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { inherits } from "util";
import { useResponsive } from "hooks/useResponsive";

interface SkeletonProps {
  lines?: number;
  width?: number;
}

const SkeletonAdvertisementBenefitsList: React.FC<SkeletonProps> = ({
  lines,
}) => {
  const {
    matchesXS,
    matchesSM,
    matchesMD,
    matchesLG,
    downXS,
    downLG,
    downXL,
    downSM,
  } = useResponsive();

  let gridItemsCount;
  if (downSM) {
    gridItemsCount = 12;
  } else if (downXL) {
    gridItemsCount = 6;
  } else {
    gridItemsCount = 3;
  }

  const gridItem = (
    <Grid xs={gridItemsCount}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          // marginX: "auto",
          flexDirection: "column",
          width: "100%",
        }}>
        <Box
          sx={{
            width: "auto",
            px: 4,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}>
          <Skeleton
            variant="circular"
            sx={{ width: "200px", height: "200px" }}
          />
          <Box sx={{ px: 1 }}>
            <Skeleton
              variant="text"
              width="300px"
              sx={{ fontSize: "30px", mt: 2 }}
            />
          </Box>
        </Box>
      </Box>
    </Grid>
  );

  return (
    <Box sx={{ width: "100%", height: "auto" }} className="">
      <Box
        sx={{
          width: "100%",
          minHeight: "960px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          mt: 4,
          //   maxHeight: "620px",

          //   margin: "auto",
          //   padding: "20px 0px 20px 0px",
        }}>
        <Skeleton
          variant="text"
          sx={{
            width: { xs: "300px", md: "400px" },
            fontSize: { xs: "24px", md: "34px" },
            mb: 4,
          }}
        />
        <Grid
          container
          sx={{
            // maxWidth: "1522px",
            width: "100%",
            overflow: "hidden",
            mt: 4,
          }}
          spacing={2}>
          {Array(lines)
            .fill(null)
            .map((_, index) => (
              <React.Fragment key={index}>{gridItem}</React.Fragment>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

export { SkeletonAdvertisementBenefitsList };
