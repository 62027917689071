import * as React from "react";
import { Box, Divider } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Unstable_Grid2";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { inherits } from "util";
import { useResponsive } from "hooks/useResponsive";

interface SkeletonProps {
  lines?: number;
  width?: number;
}

const SkeletonTestimonialList: React.FC<SkeletonProps> = ({ lines }) => {
  const {
    matchesXS,
    matchesSM,
    matchesMD,
    matchesLG,
    downXS,
    downLG,
    downXL,
    downSM,
  } = useResponsive();

  let gridItemsCount;
  if (downSM) {
    gridItemsCount = 12;
  } else if (downXL) {
    gridItemsCount = 6;
  } else {
    gridItemsCount = 3;
  }

  const gridItem = (
    <Grid xs={12}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          // marginX: "auto",
          flexDirection: "column",
          width: "100%",
        }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            // marginX: "auto",
            flexDirection: "column",
            width: "100%",
            maxWidth: "900px",
          }}>
          <Skeleton
            variant="text"
            width="290px"
            sx={{ fontSize: "24px", mt: 2 }}
          />
          <Skeleton
            variant="text"
            width="330px"
            sx={{ fontSize: "46px", mt: 1 }}
          />
          <Box
            sx={{
              mt: 4,
              width: "90%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}>
            <Skeleton
              variant="text"
              sx={{
                fontSize: "18px",
                width: { xs: "90%", md: "100%" },
                maxWidth: "800px",
              }}
            />
            <Skeleton
              variant="text"
              sx={{
                fontSize: "18px",
                width: { xs: "80%", md: "100%" },
                maxWidth: "780px",
              }}
            />
            <Skeleton
              variant="text"
              sx={{
                fontSize: "18px",
                width: { xs: "92%", md: "100%" },
                maxWidth: "760px",
              }}
            />
            <Skeleton
              variant="text"
              sx={{
                fontSize: "18px",
                width: { xs: "86%", md: "100%" },
                maxWidth: "790px",
              }}
            />
            <Skeleton
              variant="text"
              sx={{
                fontSize: "18px",
                width: { xs: "85%", md: "100%" },
                maxWidth: "810px",
              }}
            />
            <Skeleton
              variant="text"
              sx={{
                fontSize: "18px",
                width: { xs: "50%", md: "100%" },
                maxWidth: "500px",
              }}
            />
          </Box>
          <Skeleton
            variant="text"
            width="320px"
            sx={{ fontSize: "36px", mt: 2 }}
          />
          <Skeleton
            variant="text"
            width="200px"
            sx={{ fontSize: "32px", mt: 1 }}
          />
        </Box>
      </Box>
    </Grid>
  );

  return (
    <Box sx={{ width: "100%", height: "auto", py: 8 }} className="">
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          mt: 4,
        }}>
        <Grid
          container
          sx={{
            // maxWidth: "1522px",
            width: "100%",
            overflow: "hidden",
            mt: 4,
          }}
          spacing={2}>
          {Array(lines)
            .fill(null)
            .map((_, index) => (
              <React.Fragment key={index}>{gridItem}</React.Fragment>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

export { SkeletonTestimonialList };
