import React from "react";

import { AdvertisementDto } from "dto/static/advertisement.dto";
import { AdvertisementType } from "tools/types/advertisementtype";
import { AdvertisementSection } from "tools/types/advertisementsection";
import { AdvertisementItemHomeTop } from "./AdvertisementItemHomeTop";
import { AdvertisementItemImage } from "./AdvertisementItemImage";
import { AdvertisementItemBenefits } from "./AdvertisementItemBenefits";
import { AdvertisementLoginBenefits } from "./AdvertisementLoginBenefits";

type Props = {
  item: AdvertisementDto;
  index: number;
};

const AdvertisementItem: React.FC<Props> = ({ item, index }) => {
  if (!item) return null;

  if (item?.type === AdvertisementType.IMAGE_SIMPLE
    && item?.section === AdvertisementSection.BENEFITS
  ) return <AdvertisementItemBenefits item={item} index={index} />;
  if (item?.type === AdvertisementType.IMAGE_SIMPLE
    && item?.section === AdvertisementSection.HOME_TOP
  ) return <AdvertisementItemImage item={item} index={index} />;
  if (
    item?.type === AdvertisementType.IMAGE_CTA &&
    item?.section === AdvertisementSection.HOME_TOP
  )
    return <AdvertisementItemHomeTop  item={item} index={index} />;
    if (
      item?.type === AdvertisementType.IMAGE_SIMPLE &&
      item?.section === AdvertisementSection.LOGIN_PAGE_BENEFITS
    )
      return <AdvertisementLoginBenefits item={item} index={index} />;
  return null;
};

export { AdvertisementItem };
