import React from "react";

import { IconButton, Stack, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

type Props = {
  value: number | string;
  setValue: (value: number | string) => void;
};
const InputQuantity: React.FC<Props> = ({ value, setValue }) => {
  const handleChange = (event: any) => {
    if (event.target.value === "") {
      setValue("");
      return;
    }
    if (event.target.value < 1) return;
    setValue(event.target.value);
  };

  const handleIncrement = () => {
    if (value === "") setValue(1);
    const newValue = parseInt(value.toString()) + 1;
    setValue(newValue);
  };

  const handleDecrement = () => {
    const currentValue = parseInt(value.toString());
    if (currentValue < 2) return;
    setValue(currentValue - 1);
  };

  const onBlur = () => {
    if (value === "") setValue(1);
  };

  const disabledDecrement = parseInt(value.toString()) < 2 || value === "";
  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}>
      <IconButton onClick={handleDecrement} disabled={disabledDecrement}>
        <RemoveIcon />
      </IconButton>
      <TextField
        value={value}
        onFocus={handleChange}
        onChange={handleChange}
        onBlur={onBlur}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",

          "& input": {
            textAlign: "center",
          },
          minWidth: "50px",
        }}
        fullWidth
      />
      <IconButton onClick={handleIncrement}>
        <AddIcon />
      </IconButton>
    </Stack>
  );
};

export { InputQuantity };
