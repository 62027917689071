import { useDrawer } from "hooks/useDrawer";
import React from "react";
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  Skeleton,
  SxProps,
} from "@mui/material";
import { Config } from "tools/utils/config";
import { CategoryProductDto } from "dto/product/categoryproduct.dto";
import { DrawerMainItem } from "./DrawerMainItem";
import { DrawerCloseButton } from "./DrawerCloseButton";

import { DrawerItemPhone } from "./DrawerItemPhone";
import { UserDrawerMenu } from "components/user/menu/UserDrawerMenu";
import { DrawerNavigator } from "components/static/menu/drawer/DrawerNavigator";
import { LanguageSiteOptions } from "components/static/language/LanguageSiteOptions";
import { useResponsive } from "hooks/useResponsive";
import { SearchField } from "components/product/search/SearchField";

const MyDrawer: React.FC = () => {
  const { openMainDrawer, closeAllDrawers, mainCategoryObjects } = useDrawer();
  const { downMD } = useResponsive();
  const processMainItem = (item: CategoryProductDto, index: number) => {
    return <DrawerMainItem key={index} item={item} />;
  };
  const processMainList = () => {
    if (!mainCategoryObjects) return <Skeleton />;
    if (!mainCategoryObjects.length) return null;
    return mainCategoryObjects.map((item, index) =>
      processMainItem(item, index)
    );
  };

  return (
    <Drawer
      open={openMainDrawer}
      onClose={closeAllDrawers}
      anchor={Config.DRAWER_ANCHOR}>
      <Box sx={{ position: "relative" }}>
        <Box
          sx={{
            mt: 3,
            ml: "24px",
            width: "auto",
            position: "absolute",
            left: 0,
            top: 0,
            zIndex: 10,
            display: downMD ? "block" : "none",
          }}>
          <LanguageSiteOptions colorSvg={"black"} />
        </Box>
        <DrawerCloseButton />
      </Box>
      <Box
        sx={{
          mt: 8,
          ml: 2,
          mr: 2,
          display: downMD ? "block" : "none",
          px: 2,
        }}>
        <SearchField handleAction={closeAllDrawers} />
      </Box>
      <Box
        sx={{
          width: { xs: "auto", sm: Config.DRAWER_WIDTH_MAIN },
          mt: downMD ? 1 : 5,
          fontSize: { xs: "16px", sm: "14px" },
          px: { xs: 2, sm: 0 },
        }}>
        <List>{processMainList()}</List>
        <Box sx={{ px: { xs: 2, sm: 4 } }}>
          <Divider sx={styleDivider} />
        </Box>
        <UserDrawerMenu />
        <Box sx={{ px: { xs: 2, sm: 4 } }}>
          <Divider sx={styleDivider} />
        </Box>
        <DrawerNavigator />
        <List>
          <ListItem key="tel">
            <ListItemButton>
              <DrawerItemPhone />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
};
const styleDivider: SxProps = {
  backgroundColor: "#adadad",
  width: "auto",
  px: 10,
};
export { MyDrawer };
