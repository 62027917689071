import React from "react";

import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { ProductDto } from "dto/product/product.dto";
import { CommonTools } from "tools/utils/common.tool";
import { RouteTools } from "tools/utils/route.tool";
import { CartButton } from "./CartButton";
import { useProduct } from "hooks/useProduct";
import { ProductOffer } from "./ProductOffer";
import { useLabel } from "hooks/useLabel";
import { MyIcon } from "components/elements/icon/MyIcon";
import { IconType } from "components/elements/icon/IconContext";

type Props = {
  item: ProductDto;
};
const ProductItem: React.FC<Props> = ({ item }) => {
  const { isProductOutOfStock } = useProduct();
  const { LL } = useLabel();
  const navigate = () => {
    const url = CommonTools.processObjectField(item, ["_urlObj", "urlfull"]);
    if (!url) return;

    RouteTools.setHistory(url, {});
  };
  if (!item) return null;
  const isOutOfStock = isProductOutOfStock(item);
  return (
    <Box
      sx={{
        px: 2,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        flexDirection: "column",
        py: 1,
        mb: 2,
        // background: "red",
      }}>
      <Card
        sx={{
          width: "100%",
          maxWidth: "460px",
          // height: "100%",
          position: "relative",
          opacity: isOutOfStock ? 0.5 : 1,
          borderRadius: "0px",
        }}
        elevation={0}>
        <CardActionArea
          onClick={navigate}
          sx={{ boxShadow: "none", px: 1, py: 1 }}>
          {CommonTools.processImageUrl(
            CommonTools.processObjectField(item, ["_galleryDefault", "cdnurl"]),
            500,
            500
          ) ? (
            <CardMedia
              sx={{
                minHeight: { xs: "70vw", sm: "435px" },
                objectFit: "cover",
              }}
              component="img"
              height="13"
              width="13"
              image={CommonTools.processImageUrl(
                CommonTools.processObjectField(item, [
                  "_galleryDefault",
                  "cdnurl",
                ]),
                500,
                500
              )}
              alt={CommonTools.processObjectField(item, ["_name"])}
            />
          ) : (
            <Box
              sx={{
                minHeight: "435px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "gray",
              }}>
              <MyIcon
                type={IconType.NO_IMAGE}
                colorSvg="white"
                height="100px"
                width="100px"
              />
            </Box>
          )}
          {isOutOfStock && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "calc(100% - 54px)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // transform: "translateY(-50%), translateX(-50%)",
              }}>
              <Typography
                color="error"
                sx={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  border: "2px solid red",
                  borderRadius: "6px",
                  px: 2,
                  rotate: "-45deg",
                }}>
                {LL("outOfStock")}
              </Typography>
            </Box>
          )}

          <CardContent
            sx={{
              minHeight: "54px",
              // display: "flex",
              // justifyContent: "center",
              // alignItems: "start",
              // flexDirection: "column",
              px: 0,
            }}>
            {/* <Typography sx={{ color: "gray", fontSize: 12 }}>
            {CommonTools.processObjectField(item, ["refcode"])}
          </Typography> */}
            <Box sx={{ width: "100%" }}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                  minHeight: "40px",
                  textAlign: "start",
                }}>
                {CommonTools.processObjectField(item, ["_name"])}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}>
              <Typography
                sx={{
                  fontSize: 16,
                }}>
                {CommonTools.processObjectField(item, ["_shortdescription"])}
              </Typography>
              <ProductOffer item={item} />
            </Box>
          </CardContent>
        </CardActionArea>
        <CardActions
          sx={{
            position: "absolute",
            zIndex: 20,
            top: { xs: "auto", sm: 360 },
            bottom: { xs: 120, sm: "auto" },
            right: { xs: 20, sm: 30 },
          }}>
          <Box sx={{ maxWidth: "74px", maxHeight: "74px" }}>
            <CartButton object={item} />
          </Box>
        </CardActions>
      </Card>
    </Box>
  );
};

export { ProductItem };
