import { Box, Typography } from "@mui/material";
import { HtmlTag } from "components/elements/display/HtmlTag";
import { TestimonialDTO } from "dto/static/testimonial.dto";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";

type Props = {
  item: TestimonialDTO;
};

const TestimonialItem: React.FC<Props> = ({ item }) => {
  if (!item) return null;
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          fontFamily: "Times New Roman, Times, serif",
          fontSize: { xs: "18px", md: "20px" },
        }}
        className="">
        <HtmlTag
          content={CommonTools.processObjectField(item, ["_description"])}
        />
      </Box>
      <Box sx={{ mt: 4 }}>
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: "bold",
          }}>
          {CommonTools.processObjectField(item, ["_author"])}
        </Typography>
      </Box>
      <Box>
        <Typography sx={{ fontSize: "18px", textTransform: "uppercase" }}>
          {CommonTools.processObjectField(item, ["_name"])}
        </Typography>
      </Box>
    </Box>
  );
};
export { TestimonialItem };
