import { Box, Typography } from "@mui/material";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { ProductDto } from "dto/product/product.dto";
import { useResponsive } from "hooks/useResponsive";

import { useList } from "hooks/useList";
import React from "react";
import { ProductService } from "services/product/product.service";
import { Status } from "tools/types/status";
import { ProductItem } from "../list/ProductItem";
import { useLabel } from "hooks/useLabel";
import { Carousel } from "components/elements/carousel/MyCarousel";
import { CommonTools } from "tools/utils/common.tool";
import { useConfig } from "hooks/useConfig";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { SkeletonProductListHome } from "components/elements/skeleton/product/SkeletonProductListHome";

const service = new ProductService();
const ProductListHome: React.FC = () => {
  const { LL } = useLabel();
  const { CC } = useConfig();
  const getList = (cb?: any, cbParams?: any, req?: RequestListDTO) => {
    service.getList(cb, cbParams, req);
  };
  const { downSM, downMD, downLG, downXL } = useResponsive();

  const [loading, objects] = useList<ProductDto>(
    getList,
    new RequestListDTO(
      [
        RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
        RequestFilterDTO.prepareFilter("showonhome", ["1"]),
      ],
      1,
      -1,
      [RequestSortCriteriaDTO.prepareSortCriteria("order", true)]
    ),
    [],
    true,
    "productlisthome"
  );

  const processItem = (item: ProductDto, index: number) => {
    if (!item) return null;

    return (
      <Box
        // data-aos="fade-up"
        // data-aos-anchor-placement="top-bottom"
        // data-aos-delay={`${index * 300}`}
        sx={{ height: "100%", py: 2 }}
        key={index}
      >
        <ProductItem item={item} />
      </Box>
    );
  };
  if (loading)
    return (
      <Box sx={{ mt: 3 }}>
        <SkeletonProductListHome lines={4} />
      </Box>
    );
  if (!objects) return null;
  if (!objects.length) return null;

  let slidesToShow;
  if (downSM) {
    slidesToShow = CommonTools.calculateNumberOfElementsShowOnCarousel(
      1,
      objects.length
    );
  } else if (downMD) {
    slidesToShow = CommonTools.calculateNumberOfElementsShowOnCarousel(
      1,
      objects.length
    );
  } else if (downLG) {
    slidesToShow = CommonTools.calculateNumberOfElementsShowOnCarousel(
      2,
      objects.length
    );
  } else if (downXL) {
    slidesToShow = CommonTools.calculateNumberOfElementsShowOnCarousel(
      3,
      objects.length
    );
  } else {
    slidesToShow = CommonTools.calculateNumberOfElementsShowOnCarousel(
      parseInt(CC("DefaultValueShowHomeCourseCarousel", "4")),
      objects.length
    );
  }

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Box sx={{ maxWidth: "1920px", width: "100%" }}>
        <Box sx={{ display: "flex", justifyContent: "center", py: 5 }}>
          <Typography
            sx={{ fontSize: { xs: "36px", md: "48px" }, fontWeight: "bold" }}
          >
            {LL("product_list_home_page")}
          </Typography>
        </Box>
        <Box sx={{ px: 4, py: 3 }}>
          <Carousel _slidesToShow={slidesToShow}>
            {objects.map((item: ProductDto, index: number) =>
              processItem(item, index)
            )}
          </Carousel>
        </Box>
      </Box>
    </Box>
  );
};

export { ProductListHome };
