import { Box, List } from "@mui/material";
import { CartDto } from "dto/sale/cart.dto";
import { useCart } from "hooks/useCart";
import React from "react";
import { CartItem } from "./CartItem";
import { TotalPriceBlock } from "./TotalPriceBlock";
import { useResponsive } from "hooks/useResponsive";

const CartBlock: React.FC = () => {
  const { matchesCustom } = useResponsive();
  const { cartObjects } = useCart();
  const processItem = (item: CartDto, index: number) => {
    return <CartItem key={index} item={item} />;
  };
  return (
    <Box
      sx={{
        px: matchesCustom ? 4 : 0,
      }}>
      <Box
        sx={{
          width: "100%",
          maxHeight: "750px",
          overflowY: "auto",
        }}>
        <List sx={{ px: { xs: 0, md: 4 } }}>
          {cartObjects.map((item, index) => processItem(item, index))}
        </List>
      </Box>
      <Box sx={{ width: "100%" }}>
        <TotalPriceBlock />
      </Box>
    </Box>
  );
};

export { CartBlock };
