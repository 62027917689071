import {
  Box,
  ImageList,
  ImageListItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { GalleryDto } from "dto/system/gallery.dto";
import { Image } from "components/elements/display/Image";

import { usePage } from "hooks/usePage";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";
import { Config } from "tools/utils/config";
import { SkeletonGalleryHome } from "components/elements/skeleton/SkeletonGalleryHome";

const GalleryHome: React.FC = () => {
  const theme = useTheme();
  const isMD = useMediaQuery(theme.breakpoints.down("md"));
  const id = Config.ID_GALLERY_HOME_PAGE;
  const { object, loading } = usePage(id, true, "gallery_block_home");

  const processItem = (item: GalleryDto, index: number) => {
    if (!item) return null;
    const col = isMD ? 2 : 1;
    const row = index === 2 ? 2 : 1;

    return (
      <ImageListItem
        key={index}
        cols={col}
        rows={row}
        sx={{ overflow: "hidden" }}>
        <Image
          url={CommonTools.processObjectField(item, ["cdnurl"])}
          sizeInPercentage={{ width: "100%", height: "100%" }}
          typeMeasure="percentage"
          withLinK={false}
          cutImageHeight={row === 2 ? 958 : 470}
          cutImageWidth={1000}
          style={{ objectFit: "cover" }}
        />
      </ImageListItem>
    );
  };

  if (!id) return null;
  if (loading)
    return (
      <Box sx={{ mt: 3 }}>
        <SkeletonGalleryHome />
      </Box>
    );
  if (!object) return null;
  if (!object._galleryActiveList) return null;
  if (!object._galleryActiveList.length) return null;
  const objects = object._galleryActiveList.sort(
    (a, b) => (a.order ?? 0) - (b.order ?? 0)
  );

  return (
    // <Box
    //   sx={{
    //     height: "auto",
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "cenetr",
    //   }}>
    //   <ImageList
    //     sx={{ width: "100%", height: "auto", maxWidth: "1920px" }}
    //     variant="quilted"
    //     cols={4}>
    //     {objects.map((item: GalleryDto, index: number) =>
    //       processItem(item, index)
    //     )}
    //   </ImageList>
    // </Box>
    <Box
      sx={{
        // height: { xs: "auto", md: "958px" },
        maxHeight: { xs: "auto", md: "958px" },
        height: { xs: "200vw", md: "50vw" },
        display: "flex",
        justifyContent: "center",
        alignItems: "cenetr",
      }}>
      <ImageList
        sx={{ width: "100%", height: "auto", maxWidth: "1920px" }}
        variant="quilted"
        cols={4}>
        {objects.map((item: GalleryDto, index: number) =>
          processItem(item, index)
        )}
      </ImageList>
    </Box>
  );
};

export { GalleryHome };
