import { Box, Typography } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import { OrderDto } from "dto/sale/order.dto";
import { useLabel } from "hooks/useLabel";
import React from "react";
import { PayMethodType } from "tools/types/paymethod";
import { Status } from "tools/types/status";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

type Props = {
  object: OrderDto;
};

const PayAction: React.FC<Props> = ({ object }) => {
  const { LL } = useLabel();
  if (!object) return null;
  if (object.paystatus !== Status.ORD_P_UNPAID) return null;
  if (object.paymethod === PayMethodType.PAYPAL) {
    return (
      <Box
        sx={{ width: { xs: "100%", md: "200px" }, height: "45px" }}
        className="whiteButton listItemButton"
      >
        <MyButton
          sx={{
            height: "100%",
            // background: "red",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Typography sx={{ mt: "2px", ml: 2, fontWeight: "600" }}>
            {LL("paywithpaypal")}
          </Typography>
          <Box
            sx={{
              width: "20px",
              mt: "3px",
              display: { xs: "none", sm: "flex" },
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <KeyboardArrowRightIcon className="iconSvg3" />
          </Box>
        </MyButton>
      </Box>
    );
  }
  return null;
};

export { PayAction };
