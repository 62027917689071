import { Loading } from "components/elements/loading/Loading";
import { useReactPath } from "hooks/useReactPath";
import { useResource } from "hooks/useResource";
import { LoginPage } from "pages/authentication/LoginPage";
import { ForbiddenPage } from "pages/general/ForbiddenPage";
import { HomePage } from "pages/general/HomePage";
import { NotFoundPage } from "pages/general/NotFoundPage";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { RouteTools } from "tools/utils/route.tool";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { useDrawer } from "hooks/useDrawer";
import { MyDrawer } from "components/drawer/MyDrawer";
import { SecondDrawer } from "components/drawer/SecondDrawer";
import { CategoryProductPage } from "pages/product/CategoryProductPage";
import { ProductPage } from "pages/product/ProductPage";
import { StaticPage } from "pages/static/StaticPage";
import { BlogStatic } from "pages/static/BlogStatic";
import { BlogCategoryPage } from "pages/static/BlogCategoryPage";
import { Provider as BlogCategoryProvider } from "contexts/categoryblog.context";

import { CheckoutPage } from "pages/sale/CheckoutPage";
import { SignUpPage } from "pages/authentication/SignUpPage";
import { ProfilePage } from "pages/user/ProfilePage";

import { SearchPage } from "pages/product/SearchPage";
import { OrderInfoPage } from "pages/sale/OrderInfoPage";
import { ForgotPasswordPage } from "pages/authentication/ForgotPasswordPage";
import { ResetPasswordPage } from "pages/authentication/ResetPasswordPage";

const MainLayout: React.FC = () => {
  const { currentRoute, setCurrentRoute } = useResource();
  const location = useLocation();
  const pathObject = useReactPath();
  const [_currentpath, setCurrentPath] = useState("");
  const [_mainobject, setMainObject] = useState("");
  const { getListMain, getListSecondary } = useDrawer();
  const stylePages = { marginTop: "118px" };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const processPathObject = async () => {
    let routeobj = await RouteTools.parseLocation(location);

    routeobj.historystate = pathObject.historystate;

    if (routeobj.url == _currentpath) return;
    setCurrentPath(routeobj.url);

    setCurrentRoute(routeobj);
  };

  useEffect(() => {
    processPathObject();
  }, [pathObject]);

  useEffect(() => {
    if (currentRoute && currentRoute._mainobject) {
      setMainObject(currentRoute._mainobject);
    }
  }, [currentRoute]);

  useEffect(() => {
    getListMain();
  }, [getListMain]);

  useEffect(() => {
    getListSecondary();
  }, [getListSecondary]);

  const processStandard = () => {
    if (!_mainobject) return false;
    if (_mainobject === "homepage")
      return <HomePage currentRoute={currentRoute} />;

    if (_mainobject === "login")
      return <LoginPage currentRoute={currentRoute} />;

    if (_mainobject === "signup") {
      return <SignUpPage currentRoute={currentRoute} />;
    }
    if (_mainobject === "forgotpassword")
      return <ForgotPasswordPage currentRoute={currentRoute} />;

    if (_mainobject === "resetpassword")
      return <ResetPasswordPage currentRoute={currentRoute} />;
    
    if (_mainobject === "forbidden")
      return <ForbiddenPage currentRoute={currentRoute} />;

    if (_mainobject === "notfound")
      return <NotFoundPage currentRoute={currentRoute} />;

    return false;
  };

  const processProduct = () => {
    if (!_mainobject) return false;

    if (_mainobject === "categoryproduct")
      return (
        <div style={stylePages}>
          <CategoryProductPage currentRoute={currentRoute} />
        </div>
      );

    if (_mainobject === "product")
      return (
        <div style={stylePages}>
          <ProductPage currentRoute={currentRoute} />
        </div>
      );

    if (_mainobject === "search")
      return (
        <div style={stylePages}>
          <SearchPage currentRoute={currentRoute} />
        </div>
      );
    return false;
  };

  const processPage = () => {
    if (!_mainobject) return false;

    if (_mainobject === "page")
      return (
        <div style={stylePages}>
          <StaticPage currentRoute={currentRoute} />
        </div>
      );

    return false;
  };

  const processBlog = () => {
    if (!_mainobject) return false;

    if (_mainobject === "blog") {
      return (
        <div style={stylePages}>
          <BlogStatic currentRoute={currentRoute} />
        </div>
      );
    }

    if (_mainobject === "categoryblog") {
      return (
        <BlogCategoryProvider>
          <div style={stylePages}>
            <BlogCategoryPage currentRoute={currentRoute} />
          </div>
        </BlogCategoryProvider>
      );
    }

    return false;
  };

  const processSalesModule = () => {
    if (!_mainobject) return false;

    if (_mainobject === "checkout")
      return (
        <div style={stylePages}>
          <CheckoutPage currentRoute={currentRoute} />
        </div>
      );
    if (_mainobject === "orderinfo") {
      return (
        <div style={stylePages}>
          <OrderInfoPage currentRoute={currentRoute} />
        </div>
      );
    }
    return false;
  };

  const processUser = () => {
    if (!_mainobject) return false;
    if (_mainobject === "profile")
      return (
        <div style={stylePages}>
          <ProfilePage currentRoute={currentRoute} />
        </div>
      );
    return false;
  };

  const processMainObject = () => {
    let t: any = false;

    t = processStandard();
    if (t) return t;

    t = processUser();
    if (t) return t;

    t = processProduct();
    if (t) return t;

    t = processPage();
    if (t) return t;

    t = processBlog();
    if (t) return t;

    t = processSalesModule();
    if (t) return t;

    return <Loading />;
  };

  const processMainLayout = () => {
    return (
      <>
        <header>
          <Header currentRoute={currentRoute} />
        </header>
        <main>{processMainObject()}</main>
        <footer>
          <Footer currentRoute={currentRoute} />
        </footer>
        <MyDrawer />
        <SecondDrawer />
      </>
    );
  };

  return processMainLayout();
};

export { MainLayout };
